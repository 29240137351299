/* src/style-sheet/HelpCenter.css */

.help-center-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  margin-top: 70px;
}

.title-help-center {
  color: #d9d9d9;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.subtitles-sections-help {
  color: #d9d9d9;
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.help-paragraph {
  color: #afafaf;
  font-size: 1rem;
  margin-bottom: 20px;
}

.link-help-section {
  color: #006eff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.link-help-section:hover {
  color: #004dbf;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.icon {
  color: rgba(255, 255, 255, 0.31);
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.icon:hover {
  transform: scale(1.1);
  color: rgba(255, 255, 255, 0.447);
}

.support-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #006eff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.support-button:hover {
  background-color: #004dbf;
}

/* Media Queries */
@media (max-width: 768px) {
  
  .help-center-container {
    margin-top: 0;
  }
  .title-help-center {
    font-size: 2rem;
  }

  .subtitles-sections-help {
    font-size: 1.5rem;
  }

  .help-paragraph {
    font-size: 1rem;
  }

  .icon {
    font-size: 1.5rem;
  }
}
