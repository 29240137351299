/* Contenedor principal del contenido */
.main-container-content-home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Contenedor del titulo principal */
.container-title-home {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

/* Titulo principal */
.main-home-title {
  margin: 20px;
  font-size: 2.1rem;
  color: #d9d9d9;
  margin-top: 70px;
}

/* Contenedor seccion 1 home */
.container-one-of-home-sections {
  display: flex;
  margin: 0;
  margin-top: 50px;
}

/* Transición suave para el desplazamiento del ícono al pasar el cursor */
.container-for-start-arrows {
  position: absolute; /* Fija la posición del ícono */
  bottom: 5px; /* Lo coloca en la parte inferior del contenedor */
  left: 20px; /* Lo posiciona en el extremo izquierdo del contenedor */
  transition: transform 0.3s ease;
  color: #006eff;
}

/* Desplaza el ícono hacia la derecha cuando se pasa el cursor sobre el contenedor */
.child-container-project-content:hover .container-for-start-arrows {
  transform: translateX(10px);
  color: #d9d9d9;
}

/* Titulo decada seccion */
.title-sections-home {
  padding: 0;
  color: #d9d9d9;
  margin: 0; /* Elimina el margen predeterminado */
  font-size: 1.5rem; /* Ajusta el tamaño del título según sea necesario */
}

/* Contenedor para el título y el icono */
.title-icon-container {
  display: flex; /* Utiliza flexbox para alinear los elementos horizontalmente */
  align-items: center; /* Alinea verticalmente el icono y el título */
}

.project-icon {
  margin-right: 10px; /* Añade espacio entre el icono y el título */
  font-size: 64px; /* Ajusta el tamaño del icono según sea necesario */
  color: #4B0082;
}

/* Parrafo decada seccion */
.text-sections-home {
  color: #afafaf;
}

/* Contenedor Boton Ver mas */
.ecommerce-project-button {
  padding: 25px 0 25px;
}

/* Estilos boton ver mas */
.button-ecommerce-ecm {
  background-color: transparent; /* Fondo transparente */
  border: 2px solid #007BFF; /* Borde de color azul */
  color: #007BFF; /* Color del texto */
  font-size: 18px; /* Tamaño de fuente */
  padding: 10px 20px; /* Espaciado interno */
  cursor: pointer; /* Mostrar el puntero en hover */
  border-radius: 5px; /* Bordes redondeados */
  text-align: center; /* Centrar el texto dentro del botón */
  transition: all 0.3s ease; /* Suavizar las transiciones */
  display: inline-block; /* Alinear el botón correctamente */
  margin: 0 auto; /* Centrarse horizontalmente */
  position: absolute; /* Para centrar en la página */
  left: 50%; /* Posición horizontal */
  transform: translateX(-50%); /* Centrar el botón */
}

.button-ecommerce-ecm:hover {
  background-color: #007BFF; /* Fondo azul en hover */
  color: white; /* Color del texto en hover */
  border-color: #0056b3; /* Cambia el borde en hover */
}

/* Contenedor principal opciones boton ver mas */
.main-drop-down-information-container {
  display: flex;
  margin-top: 10rem;
  border-radius: 8px;
  overflow: hidden;
}

.container-to-handle-the-contents {
  flex: 1;
  margin-right: 20px;
  border-radius: 10px;
  padding: 0 20px 0 20px;
  background-color: #151515;
  padding-top: 40px;
}
.container-to-handle-the-contents:last-child {
  margin-right: 0;
}

.column-two-fk {
  background-color: #191919;
}

/* Contenedor hijo de las columnas */
.child-container-project-content {
  position: relative;
  padding: 0;
  flex: 1;
  margin-right: 20px;
  border-radius: 10px;
  background: linear-gradient(45deg, #ff5e0013, #00000018);
}
.child-container-project-content:last-child {
  margin-right: 0;
}

/* Enlaces de textos */
.project-link {
  text-decoration: none; /* Elimina el subrayado del enlace */
  cursor: pointer; /* Muestra la mano al pasar el cursor sobre el enlace */
}

/* Contenedor hijo columnas link */
.project-column {
  padding: 20px; /* Añade espacio interno alrededor del contenido */
  border: 1px solid #575757; /* Añade un borde gris */
  border-radius: 10px; /* Redondea las esquinas del borde */
  background: linear-gradient(45deg, #ff5e0013, #00000018); /* Añade un fondo degradado */
  transition: background 0.3s ease; /* Suaviza la transición del fondo cuando se hace hover */

}
.project-column:hover {
  background: linear-gradient(45deg, #006eff18, #ffffff18);
}



/* Sección 2 */
/* Contenedor del titulo principal two */
.container-two-title-home {
  text-align: left;
  margin-top: 50px;
}

/* Titulo */
.main-home-title-two {
  margin: 20px 0 20px 0;
  font-size: 2.1rem;
  color: #d9d9d9;
  margin-top: 70px;
}
/* Titulos secciones */
.titles-for-sections-two {
  padding: 0;
  border-bottom: 1px solid hsla(0, 0%, 87%, 0.288);
  justify-content: center;
  text-align: center;
}

/* Contenedor */
.child-container-section-one-content {
  display: flex;
  padding: 20px 0 20px 0;
  border-radius: 10px;
  margin: 10px 0;
  background: linear-gradient(90deg, rgb(21, 21, 21), #ffffff, rgb(21, 21, 21));
  margin-top: 50px;
}

/* Estilos para el contenedor del icono */
.icon-sessions-container-home {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 0 10px;
}

/* Estilos para el icono */
.home-session-icons {
  width: 60px;
  height: auto;
  color: #b8b8b8;
}

/* ---------------------------------------------//----------------------------------------------*/

/* Seccion tres */
/* Estilo general de la sección de artículos recientes */
.recent-articles-section {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
}

.title-home-content-blog {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #d9d9d9;
}

.articles-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 20px 0;
}

.blog-card-link {
  text-decoration: none;
  color: inherit;
  height: 100%; /* Asegura que el enlace ocupe toda la altura disponible */
  display: block; /* Necesario para que height: 100% funcione */
}

.blog-card-link :hover {
  background-color: #141c36;
}

.article-content {
  background-color: #1d2029;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%; /* Hace que el contenido ocupe toda la altura */
  display: flex; /* Establece un contenedor flexible */
  flex-direction: column; /* Organiza los elementos en columna */
}

.article-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  flex-shrink: 0; /* Evita que la imagen se encoja */
}

.article-title {
  padding: 15px 20px;
  margin: 0;
  font-size: 1.25rem;
  color: #d9d9d9;
  flex-shrink: 0; /* Evita que el título se encoja */
}

.article-summary {
  padding: 0 20px 20px;
  margin: 0;
  color: #7d7d7d;
  font-size: 0.9rem;
  line-height: 1.5;
  flex-grow: 1; /* Permite que el resumen ocupe el espacio restante */
  display: flex; /* Necesario para alinear el texto al principio */
  align-items: flex-start; /* Alinea el texto al principio */
}

/* Responsive design */
@media (max-width: 768px) {
  .title-home-content-blog {
    font-size: 2rem;
  }
  
  .articles-container {
    grid-template-columns: 1fr;
  }
}






/* Sección cuatro - *cambiar las clases a ingles* */
/* Seccion para  cambio de imagenes en el home */
.contenedor-principal {
  display: flex;
  position: relative;
  padding-left: 20px;
}

.contenedor-principal::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(to bottom, #6a11cb, #071733); /* Degradado para dos colores */
  z-index: 1;
}

.contenedor-principal::after {
  content: '';
  position: absolute;
  top: 0;
  left: 4px; /* Separación para simular doble borde */
  width: 2px;
  height: 0;
  background: linear-gradient(to bottom, #ff7e5f, #1e150e); /* Degradado de la segunda línea */
  transition: height 0.4s ease; /* Animación del borde */
  z-index: 0;
}

.contenedor-principal:hover::after {
  height: 100%; /* Crece hasta cubrir todo el contenedor */
}



.columna-izquierda {
  width: 50%; /* Ajusta el ancho según tus necesidades */
}

.columna-derecha {
  width: 50%; /* Ajusta el ancho según tus necesidades */
  display: flex;
  justify-content: center;
}

/* Información de los contenedores izquierdos */
.informacion {
  padding: 10px;
  margin-bottom: 10px;
  color: #006eff;
}

/* Vista previa al lado derecho */
.imagen-previa {
  max-width: 100%; /* Ajusta el ancho máximo según tus necesidades */
  max-height: 390px; /* Ajusta la altura máxima según tus necesidades */
}

/* Realta el fondo de los contenedores */
.informacion.resaltado {
  background-color: #292929; /* Cambia el color de fondo cuando se selecciona */
}

  