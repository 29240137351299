/* General Layout */
.ecommerce-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #afafaf;
  margin-top: 70px;
}

/* Header */
.ecommerce-header {
  text-align: center;
  padding: 40px 20px;
  background: linear-gradient(180deg, #3e250086, #181818); /* Degradado */
  border-bottom: 2px solid #eaeaea;
}

.ecommerce-header h2 {
  font-size: 2.5rem;
  color: #f1f1f1;
  margin-bottom: 10px;
}

.ecommerce-header p {
  font-size: 1.2rem;
  line-height: 1.6;
}

/* Technologies Section */
.technologies-section {
  padding: 40px 0px;
}

.technologies-section h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 30px;
  color: #f1f1f1;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.tech-card {
  background: linear-gradient(180deg, #3e250086, #181818); /* Degradado */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

.tech-card:hover {
  transform: translateY(-10px);
}

.tech-icon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 15px;
}

.tech-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.tech-card p {
  font-size: 1rem;
  color: #666;
}

/* Toggle Button */
.toggle-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #0056b3;
}

/* Additional Technologies Section */
.more-tech {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ecommerce-container {
    margin-top: 0;
  }

  .ecommerce-header h1 {
    font-size: 2rem;
  }

  .tech-grid, .more-tech {
    grid-template-columns: 1fr;
  }

  .toggle-button {
    width: 100%;
  }
}
