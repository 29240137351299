/* Estilos para el contenedor principal de la página de Contacto */
.main-container-contact {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  margin-top: 70px;
}

/* Estilos para los encabezados dentro del contenedor */
.container-main-title-contact .main-title-contact {
  font-size: 28px;
  margin-bottom: 20px;
  color: #d9d9d9;
}

/* Estilos para los párrafos */
.contact-paragraph {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #b8b8b8;
}

/* Estilos para el contenedor del título */
.container-title-contact {
  margin-bottom: 20px;
}

/* Estilos para el mensaje de contacto */
.contact-info {
  font-size: 1rem;
  color: #4a5568;  /* Un tono gris oscuro para el texto */
  font-weight: 500;  /* Peso de fuente moderado */
  text-align: center;  /* Centrado del texto */
  margin: 1.5rem 0;  /* Espaciado superior e inferior */
  padding: 1rem;  /* Padding alrededor del texto */
  background-color: #090909;  /* Fondo suave y claro */
  border-radius: 8px;  /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Sombra suave para resaltar */
  max-width: 600px;  /* Limitar el ancho máximo */
  margin-left: auto;
  margin-right: auto;  /* Centrado horizontal */
}

/* Estilos para el título */
.title-contact {
  font-size: 18px;
  color: #d9d9d9;
}

/* Estilos para el contenedor de párrafos */
.contact-paragraph-container {
  margin-bottom: 30px;
}

/* Estilos para el contenedor principal del formulario */
.contact-form-main-container {
  display: flex;
  gap: 20px;
}

/* Estilos para las columnas */
.container-column-two-form {
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #272727;
}

/* Estilos generales para el formulario */
.contact-form-content {
  display: flex;
  flex-direction: column;
}

/* Estilos para las etiquetas de los campos del formulario */
.form-tag-titles {
  margin-bottom: 8px;
  color: #f0f0f0;
}

/* Estilos comunes para campos de entrada y área de texto */
.form-input-field {
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #d4d4d4;
  color: #333;
  font-size: 15px;
  outline: none;
}

/* Estilos para el botón de envío */
.submit-form-button {
  padding: 10px;
  font-size: 16px;
  background-color: #006eff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-form-button:hover {
  background-color: #358dff;
}

/* Estilos para el título de las columnas */
.title-column-two {
  color: #ffffff;
}

/* Estilos para el párrafo de las columnas */
.paragraph-column-two {
  color: #ececec;
}

/* Media queries para dispositivos móviles */
@media screen and (max-width: 768px) {
  .main-container-contact {
    margin-top: 0;
  }

  .contact-form-main-container {
    flex-direction: column;
  }

  .container-column-two-form {
    margin-bottom: 20px;
  }

  .form-input-field {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .main-title-contact {
    font-size: 24px;
  }

  .contact-paragraph {
    font-size: 14px;
  }

  .form-input-field {
    font-size: 13px;
  }

  .submit-form-button {
    font-size: 14px;
    padding: 8px;
  }

  .title-contact {
    font-size: 16px;
  }
}
