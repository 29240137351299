/* Contenedor principal */
.search-bar-container {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  font-family: system-ui, -apple-system, sans-serif;
}

/* Barra de búsqueda */
.search-bar {
  display: flex;
  align-items: center;
  background-color: #111111;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 4px 6px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Input de búsqueda */
.search-input {
  flex: 1;
  padding: 14px 16px;
  border: none;
  outline: none;
  font-size: 16px;
  color: #ccc;
  background: transparent;
  width: 100%;
  letter-spacing: 0.01em;
}

.search-input::placeholder {
  color: #9ca3af;
  transition: color 0.2s ease;
}

.search-input:focus::placeholder {
  color: #c7c7c7;
}

/* Botón de búsqueda */
.search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-right: 4px;
}

.search-icon {
  width: 20px;
  height: 20px;
  color: #737373;
}

/* Contenedor de resultados */
.search-results {
  position: absolute;
  top: calc(100% + 0px);
  left: 0;
  right: 0;
  background-color: #1e1e1e; /* Fondo oscuro cálido */
  border-radius: 10px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.08),
              0 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 50;
  max-height: 450px;
  overflow-y: auto;
  animation: slideDown 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(8px);
}

/* Animación mejorada */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-8px) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* Elementos de resultados */
.search-result-item {
  display: flex;
  align-items: center;
  padding: 14px 18px;
  color: #ccc;
  text-decoration: none;
  transition: all 0.2s ease;
  border-bottom: 1px solid hsla(0, 0%, 31%, 0.066);
  position: relative;
  overflow: hidden;
}

.search-result-item:hover {
  background-color: #2a2a2a;
}


/* Icono en resultados */
.search-result-item .result-icon {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  color: #6b7280;
  flex-shrink: 0;
  transition: color 0.2s ease;
}

/* Contenedor del texto del resultado */
.result-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.result-title {
  font-weight: 500;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-category {
  font-size: 12px;
  color: #6b7280;
  transition: color 0.2s ease;
}

.search-result-item:hover .result-category {
  color: #4f46e5;
}

/* Mensaje de no resultados */
.no-results {
  padding: 20px;
  text-align: center;
  color: #6b7280;
  font-style: italic;
  background: #f9fafb;
  border-radius: 16px;
  margin: 8px;
}

/* Ver todos los resultados */
.search-result-item.view-all {
  background-color: #f8fafc;
  color: #4f46e5;
  font-weight: 500;
  justify-content: center;
  padding: 16px;
}

.search-result-item.view-all:hover {
  background-color: #f1f5f9;
  padding-left: 16px;
}

/* Scrollbar personalizado */
.search-results::-webkit-scrollbar {
  width: 8px;
}

.search-results::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.search-results::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 4px;
  border: 2px solid #f1f1f1;
}

.search-results::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

/* Estilos responsivos mejorados */
@media (max-width: 640px) {
  .search-bar-container {
    max-width: 100%;
    margin: 0 12px;
  }

  .search-input {
    font-size: 15px;
    padding: 12px 14px;
  }

  .search-button {
    padding: 8px;
  }

  .search-results {
    max-height: 60vh;
    border-radius: 12px;
  }

  .search-result-item {
    padding: 12px 16px;
  }
}

/* Soporte para modo oscuro */
@media (prefers-color-scheme: dark) {
  .search-bar {
    background-color: #1f2937;
    border-color: #374151;
  }

  .search-input {
    color: #e5e7eb;
  }

  .search-input::placeholder {
    color: #6b7280;
  }

  .search-results {
    background-color: #1f2937;
    border-color: #374151;
  }

  .search-result-item {
    color: #e5e7eb;
    border-bottom-color: #374151;
  }

  .search-result-item:hover {
    background-color: #273548;
  }

  .no-results {
    background-color: #273548;
    color: #9ca3af;
  }

  .result-category {
    color: #9ca3af;
  }
}