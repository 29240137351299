/* SearchResultsDisplay.css */

/* Variables CSS */
:root {
  --container-max-width: 800px; /* Reducido para mejor lectura en formato vertical */
  --primary-gradient: linear-gradient(to right, #4299e1, #667eea);
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-hover: 0 4px 12px rgba(0, 0, 0, 0.1);
  --spacing-base: 1rem;
}

/* Contenedor principal */
.search-results-container-enter-or-search-icon {
  max-width: var(--container-max-width);
  max-width: 1200px;
  margin: var(--spacing-base) auto;
  margin-top: 70px;
  padding: var(--spacing-base);
  border-radius: 8px;
  box-shadow: var(--shadow-sm);
}

/* Título de resultados */
.title-search-result-for-enter {
  font-size: 1.25rem;
  color: #d9d9d9;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #edf2f7;
  position: relative;
}

.title-search-result-for-enter::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 60px;
  height: 2px;
  background: var(--primary-gradient);
}

/* Lista de resultados - Manteniendo estructura vertical */
.search-results-list-enter {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Elemento individual de resultado */
.enter-search-result-element {
  padding: 1.25rem;
  background: #1b1b1b;
  border: 1px solid rgba(226, 232, 240, 0.174);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.enter-search-result-element:hover {
  border-color: #0A84FF;
  box-shadow: var(--shadow-hover);
}

/* Badge de tipo de contenido */
.content-type-badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
}

.content-type-badge.blog {
  background: #ebf8ff;
  color: #2b6cb0;
}

.content-type-badge.project {
  background: #f0fff4;
  color: #2f855a;
}

/* Enlaces y títulos */
.search-result-link-enter {
  text-decoration: none;
  display: block;
  margin-bottom: 0.5rem;
}

.result-title {
  color: #0865fe;
  font-size: 1.125rem;
  margin: 0.5rem 0;
  font-weight: 600;
}

.search-result-link-enter:hover .result-title {
  text-decoration: underline;
}

/* Resumen y contenido */
.post-summary {
  color: #d9d9d9;
  font-size: 0.9375rem;
  line-height: 1.5;
  margin: 0.75rem 0;
}

.post-content {
  color: #d9d9d9;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Mensaje de no resultados */
.no-results-found-enter {
  text-align: center;
  padding: 2rem;
  color: #718096;
  font-size: 1rem;
  background: #f7fafc;
  border-radius: 8px;
  border: 2px dashed #e2e8f0;
}

/* Adaptaciones responsivas */
@media (max-width: 768px) {
  .search-results-container-enter-or-search-icon {
    width: 95%;
    margin-top: 60px;
    padding: 0.75rem;
  }

  .enter-search-result-element {
    padding: 1rem;
  }

  .title-search-result-for-enter {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  .result-title {
    font-size: 1rem;
  }

  .post-summary {
    font-size: 0.875rem;
  }

  .post-content {
    font-size: 0.8125rem;
  }
}

@media (max-width: 480px) {
  .search-results-container-enter-or-search-icon {
    width: 100%;
    margin-top: 10px;
    padding: 0.5rem;
    border-radius: 0;
  }

  .content-type-badge {
    padding: 0.2rem 0.5rem;
    font-size: 0.7rem;
  }

  .enter-search-result-element {
    padding: 0.875rem;
    border-radius: 6px;
  }

  .no-results-found-enter {
    padding: 1.5rem;
  }
}

/* Soporte para modo oscuro */
@media (prefers-color-scheme: dark) {
  .search-results-container-enter-or-search-icon {
    background: #1a202c;
  }

  .enter-search-result-element {
    background: #2d3748;
    border-color: #4a5568;
  }

  .result-title {
    color: #63b3ed;
  }

  .post-summary {
    color: #e2e8f0;
  }

  .post-content {
    color: #a0aec0;
  }

  .no-results-found-enter {
    background: #2d3748;
    border-color: #4a5568;
    color: #e2e8f0;
  }
}