/* Contenedor principal del footer */
.main-container-of-footer-number-one {
  padding: 20px;
  margin: 0;
  background-color: #222222;
}

/* Contenedor navegacion */
.navigation-columns-container {
  display: flex;
  justify-content: space-around;  /* Ajusta el espacio entre las columnas */
  align-items: flex-start;       /* Ajusta la alineacion vertical de las columnas */
  flex-wrap: wrap;               /* Permite que las columnas se envuelvan a la siguiente linea si no caben en el ancho del contenedor */
  max-width: 1200px;
  margin: 0 auto;
}

/* Estilo para cada columna individual */
.navigation-columns-container .information-columns {
  flex: 1;                       /* Hace que las columnas ocupen el mismo espacio */
  margin: 10px;                  /* Ajusta el espacio entre las columnas */
  text-align: center;            /* Ajusta la alineación del texto dentro de las columnas */
}

/* Estilo para el enlace del logo */
.Logo-link-footer {
  text-decoration: none;
  color: inherit;  /* Conserva el color del texto del enlace */
}

/* Estilo para el logo */
.Logo-text-fp {
  width: 100px;  /* Ajusta el ancho del logo segun tus necesidades */
  height: auto;  /* Mantiene la proporcion del logo */
  display: block; /* Evita el espacio extra debajo de la imagen */
  margin: 0 auto; /* Centra el logo horizontalmente */
}

/* Estilo para la descripcion del logo */
.text-in-logo-description {
  margin-top: 5px; /* Ajusta el espacio entre el logo y la descripcion */
  font-size: 15px; /* Ajusta el tamaño de la fuente de la descripcion */
  line-height: 1.2; /* Altura de línea para mejorar la legibilidad */
  color: #777;    /* Color del texto de la descripcion */
}

/* Estilo para las listas ul > li */
.information-columns ul {
  list-style-type: none; /* Quita los puntos de la lista */
  padding: 0; /* Elimina el espacio de relleno predeterminado de la lista */
}

/* Estilo para los elementos de la lista */
.information-columns li {
  margin-bottom: 5px; /* Ajusta el espacio entre los elementos de la lista */
  font-size: 15px;
}

/* Cambia el color al pasar el cursor sobre los enlaces */
.navigation-columns-container a {
  color: #777; /* Color normal */
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s; /* Transición suave */
}

.navigation-columns-container a:hover {
  color: #ffffff; /* Cambia a este color al pasar el cursor */
}

.title-information-sections {
  color: #ccc;
}

/* Estilo para el contenedor de los iconos sociales */
.container-social-icons {
  display: flex; /* Muestra los iconos en forma horizontal */
  justify-content: space-around; /* Ajusta el espacio entre los iconos */
  align-items: center; /* Alinea los iconos verticalmente en el centro */
}

/* Estilo opcional para los contenedores individuales de los iconos */
.container-columns-icons {
  margin: 0 10px; /* Espacio entre los contenedores de los iconos */
}

/* Quitar el subrayado enlaces iconos */
.container-social-icons a {
  text-decoration: none;
}

/* Estilo para los iconos sociales */
.social-icons-components {
  font-size: 25px; /* Tamaño iconos */
  cursor: pointer;
}

/* Estilos para el contenedor derechos de autor */
.container-child-footer-number-two {
  text-align: center;
  margin-top: 20px;
}

/* Estilos para el texto de derechos de autor */
.footer-text {
  font-size: 14px;
  color: #777;
}




/* Media Queries para dispositivos móviles (pantallas más pequeñas) */
@media (max-width: 768px) {
  /* Flexibilidad para pantallas de tablets o móviles */
  .navigation-columns-container {
    flex-direction: column; /* Cambia la dirección a columnas */
    align-items: center;
  }

  .information-columns {
    max-width: 100%; /* Ocupar todo el ancho */
    margin-bottom: 20px; /* Espacio entre las columnas */
  }

  .collapsible-menu a {
    font-size: 14px; /* Texto más pequeño en móviles */
  }
  
  .container-social-icons {
    justify-content: center;
    gap: 15px; /* Espacio menor entre iconos en móviles */
  }

  .social-media-icons-link {
    font-size: 24px; /* Redimensiona los iconos en pantallas pequeñas */
  }
}

@media (max-width: 480px) {
  /* Ajustes adicionales para móviles pequeños */
  footer {
    padding: 0px 0px; /* Menos padding en móviles */
  }

  .text-in-logo-description {
    font-size: 13px; /* Reduce el tamaño del texto en móviles */
    max-width: 100%; /* Ocupar más espacio horizontal */
  }

  .footer-text {
    font-size: 12px; /* Texto más pequeño en móviles */
  }
}