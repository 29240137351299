/* Estilos generales para SkillsSection */
.skills-section {
  padding: 20px;
  text-align: center;
  background: linear-gradient(45deg, rgba(21, 21, 21, 0.084), rgba(0, 187, 212, 0.094));
}

.skill-item {
  display: flex;
  align-items: center;
  background-color: #1d2029;
  border: 1px solid rgb(221, 221, 221, 0.511);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  transition: transform 0.2s ease;
}

.skill-icon {
  font-size: 24px;
  color: #007bff;
  margin-right: 10px;
}

.skill-description {
  font-size: 16px;
  color: #666;
  flex: 1;
  text-align: left;
}

.toggle-details-btn {
  margin-top: 15px;
  padding: 8px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.toggle-details-btn:hover {
  background-color: #0056b3;
}

/* Adaptaciones para dispositivos móviles */
@media (max-width: 768px) {
  .skills-section {
    padding: 10px;
  }

  .section-title-mobile-tech {
    font-size: 20px;
    margin-bottom: 15px;
    color: #d7d7d7;
  }

  .skill-item {
    flex-direction: column;
    align-items: center;
  }

  .skill-icon {
    font-size: 30px;
    margin: 0 0 8px 0;
  }

  .skill-description {
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
  }

  .toggle-details-btn {
    width: 100%;
    font-size: 14px;
    padding: 10px;
  }
}
