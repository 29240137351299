/* Archivo: ProjectFrank.css */

/* Contenedor principal para alinear el contenido y dar un aspecto elegante */
.project-frank-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  padding-bottom: 5rem;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 8px;
  margin-top: 70px;
}

/* Estilo para el banner de la imagen del proyecto */
.project-banner {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.project-frank-image-banner {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-banner:hover .project-frank-image-banner {
  transform: scale(1.05);
}

/* Contenedor de contenido con espaciado y alineación */
.project-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: #333;
}

/* Título del proyecto con estilo profesional */
.project-title-fkdev {
  font-size: 2rem;
  font-weight: 700;
  color: #d9d9d9;
  margin: 0;
}

/* Descripción del proyecto */
.project-description-frank {
  font-size: 1rem;
  line-height: 1.6;
  color: #979797;
}

/* Contenedor de las tecnologías utilizadas */
.technologies {
  margin-top: 1rem;
}

.technologies h3 {
  font-size: 1.5rem;
  color: #d9d9d9;
  margin-bottom: 1.5rem;
}

/* Grid para organizar las tecnologías en filas y columnas */
.technology-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}

/* Elementos de cada tecnología */
.technology-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  border-radius: 8px;
  background-color: #202020;
}

/* Iconos de tecnología con colores vibrantes */
.technology-icon {
  font-size: 2.5rem;
  color: #707084;
  margin-bottom: 0.5rem;
}

.technology-item p {
  font-size: 0.9rem;
  color: #979797;
}

/* Estilo para el botón de GitHub */
.project-github-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  margin-top: 2rem;
  font-size: 1rem;
  color: #ffffff;
  background-color: #0A84FF;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  max-width: 200px; /* Establece un ancho máximo fijo */
  width: auto; /* Asegura que el ancho se ajuste al contenido */
  margin-left: auto;
  margin-right: auto;
}

.project-github-button:hover {
  background-color: #3097ff;
}


/* Responsividad para pantallas más pequeñas */
@media (max-width: 768px) {
  .project-frank-container {
    margin-top: 0;
  }

  .project-title {
    font-size: 1.75rem;
  }

  .project-description {
    font-size: 0.95rem;
  }

  .technologies h3 {
    font-size: 1.25rem;
  }

  .technology-grid {
    grid-template-columns: 1fr 1fr;
  }

  .project-github-button {
    font-size: 0.9rem;
    padding: 0.65rem 1.25rem;
    max-width: 150px; /* Ajusta el ancho en dispositivos más pequeños */
  }
}

@media (max-width: 480px) {
  .project-title {
    font-size: 1.5rem;
  }

  .technology-grid {
    grid-template-columns: 1fr;
  }

}
