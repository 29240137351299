/* Estilos generales para EducationCarousel */
.education-carousel {
  padding: 20px;
  background-color: #f8f9fa;
  text-align: center;
}

.section-title {
  color: #333;
}

.carousel-wrapper {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  display: flex;
  gap: 15px;
  padding-bottom: 10px;
}

.carousel-track {
  display: flex;
  width: max-content;
}

.carousel-container {
  display: flex;
  overflow-x: scroll; /* Permite el desplazamiento horizontal */
  scroll-snap-type: x mandatory; /* Ayuda a mantener las imágenes en su lugar al hacer scroll */
}

.carousel-item {
  flex: 0 0 auto;
  scroll-snap-align: start; /* Mantiene el enfoque en cada imagen al desplazarse */
  width: 80%; /* Cada imagen ocupa todo el ancho de la pantalla */
  justify-content: center; /* Centra la imagen horizontalmente */
}

.carousel-image {
  width: 95%; /* Ajusta el tamaño de la imagen */
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0; /* Elimina el margen para que las imágenes se mantengan juntas */
}


.carousel-title {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

/* Adaptaciones para dispositivos móviles */
@media (max-width: 768px) {
  .section-title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .carousel-item {
    min-width: 75%;
  }

  .carousel-image {
    border-radius: 5px;
  }
}
