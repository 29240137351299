/* Estilos generales para ProjectsSection */
.projects-section {
  padding: 20px;
}

.title-featured-projects-mobile {
  font-size: 24px;
  color: #f1f1f1;
  text-align: center;
  margin-bottom: 20px;
}

.project-link {
  text-decoration: none;
  color: inherit;
}

.project-item {
  background: linear-gradient(45deg, #ff5e0013, #00000018);
  border: 1px solid rgba(221, 221, 221, 0.511);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.2s ease;
  position: relative; /* Necesario para posicionar la flecha */
  border-top: 2px solid #00bcd4; /* Línea divisora superior */
}

.icon-title-container {
  display: flex;  
  justify-content: flex-start; /* Alinea el contenido al borde izquierdo */
}

.icon-title-container > svg {
  font-size: 50px; /* Tamaño del icono */
  margin-right: 10px; /* Espacio entre el icono y el título */
  color: #4B0082;
}

.project-title {
  font-size: 20px;
  color: #333;
  margin-left: 10px;
}

.project-description {
  font-size: 16px;
  color: #666;
  margin-top: 5px;
  flex: 1;
}

/* Estilos para el contenedor del texto y el icono */
.project-footer {
  display: flex;
  justify-content: space-between; /* Alinea el texto y el icono a los lados opuestos */
  align-items: center; /* Centra verticalmente el contenido */
  margin-top: 10px; /* Espacio superior entre la descripción y el texto del enlace */
  border-top: 1px solid #6666667c; /* Segunda línea divisora */
  width: 100%; /* Asegura que ocupe todo el ancho del contenedor */
  padding-top: 10px; /* Espacio entre la línea divisora y el contenido */
}

/* Posiciona el texto "Ir al proyecto" */
.project-text {
  font-size: 16px;
  color: #666; /* Color que resalta el texto */
  font-weight: 500;
  display: flex;
  align-items: center; /* Alinea verticalmente el texto */
}

/* Posicionamiento de la flecha */
.arrow-icon-mobile {
  font-size: 20px;
  color: #666; /* Color del icono */
  margin-left: 10px; /* Espacio entre el texto y el icono */
  position: absolute;
  right: 10px; /* Lo posiciona en la esquina derecha */
  top: 50%; /* Lo alinea verticalmente al centro */
  transform: translateY(-50%); /* Ajusta para centrarlo de forma precisa */
}

/* Adaptaciones para dispositivos móviles */
@media (max-width: 768px) {
  .projects-section {
    padding: 10px;
  }

  .title-featured-projects-mobile {
    font-size: 20px;
    margin-bottom: 15px;
    color: #d7d7d7;
  }

  .project-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .icon-title-container {
    flex-direction: row;
    justify-content: center;
  }

  .project-title {
    font-size: 18px;
  }

  .project-description {
    font-size: 14px;
    margin-top: 10px;
  }

  .project-item .project-description {
    margin-left: 75px;
  }

  .arrow-icon-mobile {
    position: static; /* Restablecer la posición en pantallas móviles */
    margin-left: 0;
    margin-top: 20px;
  }
}
