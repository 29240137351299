/* Estilos generales para la sección de YouTube */
.youtube-section {
  padding: 4rem 0 2rem;
}

.youtube-section-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #d9d9d9; /* Color moderno para el texto */
}

/* Contenedor de videos */
.youtube-videos-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Estilo de cada tarjeta de video */
.youtube-video-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.5rem;
  background-color: #1e1e1e; /* Fondo oscuro cálido */
  overflow: hidden;
  padding: 1rem;
  border: 1px solid #333333; /* Borde sutil */
}

/* Miniatura del video */
.youtube-thumbnail {
  flex: 2.5; /* Prioriza más espacio para la miniatura en relación al texto */
}

.youtube-thumbnail iframe {
  width: 100%;            /* Asegura que el iframe llene completamente su contenedor */
  height: calc(50vh - 20px); /* Mantiene la altura adecuada */
  border-radius: 8px;      /* Esquinas redondeadas */
  border: 1px solid #333333; /* Borde sutil */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
}


/* Información del video */
.youtube-video-info {
  flex: 2;
}

.youtube-video-title {
  font-size: 2rem;
  font-weight: bold;
  color: #d9d9d9; /* Azul elegante */
  margin-bottom: 0.5rem;
}

.youtube-video-description {
  font-size: 1rem;
  color: #afafaf;
  line-height: 1.5;
}

/* Responsividad */
@media (max-width: 768px) {
  .youtube-videos-container {
    flex-direction: column;
  }

  .youtube-video-card {
    flex-direction: column;
  }

  .youtube-thumbnail iframe {
    height: 200px;
  }
}
